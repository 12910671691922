export const getLocalStoredData = key => {
  // TODO: remove later
  // return "aqZpIhPhgFndhja2zFpxkK1yxGSUeaYjquB22kcC";
  try {
    const data = localStorage.getItem(key);
    if (data === null) {
      return undefined;
    }
    return data;
  } catch (err) {
    return undefined;
  }
};

export const setLocalStoredData = (key, value) => {
  try {
    const data = JSON.stringify(value);
    localStorage.setItem(key, data);
    return true;
  } catch (e) {
    return undefined;
  }
};
