import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  myCourses: [],
};

export const myCoursesSlice = createSlice({
  name: "myCourses",
  initialState,
  reducers: {
    setMyCourses: (state, action) => {
      state.myCourses = action.payload;
    },
  },
});

export const { setMyCourses } = myCoursesSlice.actions;

export default myCoursesSlice.reducer;
