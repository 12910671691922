import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import { Form, Input, Select } from "antd";
import "./MyLearning.scss";
import { fetchCourseByTitle, fetchMyCourses } from "./../../api";
import { calculatePercentage } from "./../../helper";
import { useDebounce } from "use-debounce";


const MyLearning = () => {
  const [initialFiltersData, setInitialFiltersData] = useState({ arrangement: 'ascending', progress: 'default', lecturer: 'all' })
  const [mycourses, setMycourses] = useState([])
  const [courseName, setCourseName] = useState("");
  const [debouncedCourseName] = useDebounce(courseName, 600);
  const { data, refetch } = useQuery(["my-courses-learning", "my-courses-search"], () => {
    if (!courseName) return fetchMyCourses()
    return fetchCourseByTitle(courseName)
  }, {
    onSuccess: (data) => setMycourses(data?.data?.data)
  });

  const sourceData = data?.data?.data;

  const lecturersNames = () => {
    const names = ['defaultValue'];
    for (const data of sourceData) {
      const lecturerName = data.lecturer.name;
      if (!names.includes(lecturerName)) names.push(lecturerName);
    }
    return names;
  }

  const handleArrangement = () => {
    const sortedData = sourceData.reverse();
    setMycourses([...sortedData])
    if (initialFiltersData.lecturer !== 'all') handleLecturer(initialFiltersData.lecturer)
  }

  const handleProgress = (value) => {
    const mycoursesCopy = [...mycourses];
    mycoursesCopy.forEach(course => {
      if (!isNaN(course.watched_count / course.videos_count)) {
        course.completed = (course.watched_count / course.videos_count)
      } else course.completed = 0
    })

    let sortedData;

    if (value === 'descending') sortedData = mycoursesCopy.sort((a, b) => b.completed - a.completed);
    else sortedData = mycoursesCopy.sort((a, b) => a.completed - b.completed);
    setMycourses([...sortedData])
  }

  const handleLecturer = (value) => {
    if (value !== 'all') {
      const filteredCourses = sourceData.filter(courseData => courseData.lecturer.name === value);
      setMycourses(filteredCourses)
    } else setMycourses(sourceData);
  }

  const handleCourseSearch = (e) => {
    const { value } = e.target;
    setCourseName(value);
    setInitialFiltersData({ ...initialFiltersData, lecturer: "all" })
  }

  const handleChange = (_, option) => {
    const { value, filtername } = option;
    const chooseFilterFun = {
      arrangement: handleArrangement,
      progress: handleProgress,
      lecturer: handleLecturer,
    }
    setInitialFiltersData({ ...initialFiltersData, [filtername]: value })
    chooseFilterFun[filtername](value)
  }

  useEffect(() => {
    refetch();
  }, [debouncedCourseName]);

  return (
    <section className="my-learning">
      <div className="container">
        <Form className="py-5">
          <Row className="d-flex">
            <Col xs={12} md={8} className="d-flex">
              <Form.Item label='الاضافة' className="mb-3 shadow-none flex-grow-2">
                <Select
                  defaultValue="ascending"
                  onChange={handleChange}
                  options={[
                    {
                      value: 'ascending',
                      label: 'المضاف اولا',
                      filtername: 'arrangement'
                    },
                    {
                      value: 'descending',
                      label: 'المضاف مؤخرا',
                      filtername: 'arrangement'
                    },
                  ]}
                />
              </Form.Item>

              {/* <Form.Item label="تصفية" className="me-3 mb-3">
                <Select defaultValue="category" className="me-2 shadow-none">
                  <Option value="category">حسب الفئة</Option>
                  <Option value="date">تاريخ الاضافة</Option>
                </Select>
              </Form.Item> */}

              <Form.Item label='التقدم' className="mb-3 flex-grow-2">
                <Select
                  defaultValue="default"
                  onChange={handleChange}
                  style={{ minWidth: '100px' }}
                  options={[
                    {
                      value: 'default',
                      label: '---',
                      filtername: 'progress'
                    },
                    {
                      value: 'descending',
                      label: 'الاعلي تقدما',
                      filtername: 'progress'
                    },
                    {
                      value: 'ascending',
                      label: 'الاقل تقدما',
                      filtername: 'progress'
                    },
                  ]}
                />
              </Form.Item>

              <Form.Item label='المحاضر' className="mb-3 flex-grow-1" >
                <Select
                  defaultValue="all"
                  value={initialFiltersData.lecturer}
                  onChange={handleChange}
                  options={
                    sourceData && lecturersNames()?.map(name => {
                      return name !== 'defaultValue' ? {
                        value: name,
                        label: name,
                        filtername: 'lecturer'
                      } : {
                        value: 'all',
                        label: 'الكل',
                        filtername: 'lecturer'
                      }
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={4} className="d-flex justify-content-center">
              <Form.Item className="mb-3">
                <Input
                  placeholder="ابحث عن كورس"
                  className="shadow-none"
                  onChange={handleCourseSearch}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="row my-learning__courses">
          {mycourses.map(course => (
            <div
              className="mt-3 col-md-6 col-lg-3 my-learning__card"
              key={course.id}
            >
              <Card>
                <Link to={`/course-lessons/${course.id}`}>
                  <Card.Img
                    variant="top"
                    src={`${process.env.REACT_APP_BASE_URL}/${course.course_cover_path}`}
                  />
                </Link>
                <Card.Body>
                  <Card.Title>{course.title}</Card.Title>
                  <Card.Text>
                    <small className="d-block">{course.lecturer.name}</small>
                    <small>{course.lecturer.position}</small>
                  </Card.Text>
                  <div>
                    <div className="bg-secondary">
                      <div
                        style={{
                          width: calculatePercentage(course) + "%",
                          height: "2px",
                          backgroundColor: "blue",
                        }}
                      ></div>
                    </div>
                    <small>{calculatePercentage(course)}% اكتمل</small>
                  </div>
                  <div className="my-learning__rating"></div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MyLearning;
