import React, { useState } from "react";
import { Menu } from "antd";
import { PlayCircleOutlined, FileTextOutlined } from "@ant-design/icons";
import { formatToSecondes } from "../../../../helper";
import "./Sidebar.scss";

const Sidebar = ({ modules, onChangeVideo }) => {
  const { SubMenu } = Menu;
  const [watchedVideosId, setWatchedVideosId] = useState([]);
  const [countWatchedModuleVideos, setCountWatchedModuleVideos] = useState(() => {
    const modulesAndWatchedVidoes = {}
    for (const module of modules) {
      modulesAndWatchedVidoes[module.id] = module.videos.reduce((prev, curr) => curr.is_watched ? ++prev : prev, 0)
    }
    return modulesAndWatchedVidoes;
  });

  const getCompletedModuleVideosNumber = (moduleId) => {
    setCountWatchedModuleVideos((prev) => ({
      ...prev,
      moduleId: countWatchedModuleVideos[moduleId]++
    })
    )
  }

  const addWatchedVidoes = (videoId, moduleId) => {
    if (watchedVideosId.includes(videoId)) return;
    getCompletedModuleVideosNumber(moduleId)
    setWatchedVideosId(prevState => [...prevState, videoId])
  }


  return (
    <section className="course__sidebar">
      <Menu mode="inline" className="sidebar__menu">
        {modules.map(({ id, title, videos, questions }) => (
          <SubMenu
            key={id}
            title={
              <div className="d-flex flex-column align-items-start justify-content-between">
                <span>{title}</span>
                <div className="d-flex">
                  <span>
                    {formatToSecondes(
                      videos.reduce(
                        (prevVideos, currVideo) =>
                          (prevVideos += +currVideo.playtime_seconds),
                        0
                      )
                    )}
                  </span>
                  <span className="px-3">|</span>
                  <span>{videos.length} / {countWatchedModuleVideos[id]}</span>
                </div>
              </div>
            }
          >
            {videos.map(video => (
              <Menu.Item
                key={video.id}
                className="d-flex flex-direction-column"
                onClick={() => {
                  onChangeVideo(video.id);
                  !video.is_watched && addWatchedVidoes(video.id, id);
                }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <span className="course__sidebar-title">{video.title}</span>
                  {(watchedVideosId.includes(video.id) || video.is_watched) &&
                    <input type="checkbox" defaultChecked={true} />
                  }
                  {(!watchedVideosId.includes(video.id) && !video.is_watched) &&
                    <input type="checkbox" defaultChecked={false} />
                  }
                </div>
                <span className="course__sidebar-time d-flex align-items-center">
                  <PlayCircleOutlined rotate={180} className="mx-0" />
                  <span className="ps-2">{formatToSecondes(video.playtime_seconds)}</span>
                </span>
              </Menu.Item>
            ))}
            {!!questions.length && <Menu.Item key={`module${id}questions`}
              onClick={() => onChangeVideo(`module${id}questions`, title, questions)}
            >
              <div className="d-flex justify-content-between align-items-center">
                <span className="course__sidebar-title">Module_Questions</span>
              </div>
              <span className="course__sidebar-time d-flex align-items-center">
                <FileTextOutlined className="mx-0" />
              </span>
            </Menu.Item>}
          </SubMenu>
        ))}
      </Menu>
    </section >
  );
};

export default Sidebar;
