import { Button, Form } from "react-bootstrap";
import "./registeration.scss";
import { useState } from "react";
import { useMutation } from "react-query";
import { fetchResetPassword } from "../../api/pages/reset-password";
import { useNavigate, useSearchParams } from "react-router-dom";
import { openNotification } from "../shared/Notification";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const { token } = Object.fromEntries([...searchParams])
  const [formData, setFormData] = useState({ password: '', password_confirmation: '' })
  const navigate = useNavigate();
  const { mutate } = useMutation(fetchResetPassword, {
    onSuccess: () => {
      openNotification({
        title: "تغيير كلمة المرور",
        message: "تم تغيير كلمة المرور بنجاح",
      });
      navigate("/login")
    },
    onError: () => {
      openNotification({
        title: "كلمة المرور غير متطابقة",
        message: "برجاء التاكد من البيانات المدخلة",
      });
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password.length < 6)
      return openNotification({
        title: "كلمة المرور قصيرة",
        message: "برجاء ادخال كلمة مرور مكونة من 6 احرف فأكثر",
      });

    if (formData.password !== formData.password_confirmation)
      return openNotification({
        title: "تاكيد كلمة المرور غير صحيحة",
        message: "برجاء ادخال كلمة السر وتاكيد كلمة السر مطابقتان",
      });
    mutate({ ...formData, token })
  }

  return (
    <div className="container">
      <section className="login-page row">
        <Form
          onSubmit={handleSubmit}
          className="col-md-8 col-lg-4 mx-auto mt-4 pb-2 px-4">
          <h2 className="login-title py-4">تعيين كلمة مرور جديدة</h2>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>كلمة المرور الجديدة</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="كلمة المرور الجديدة"
              required
              value={formData.password}
              onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicConfPassword">
            <Form.Label>تأكيد كلمة المرور</Form.Label>
            <Form.Control
              type="password"
              name="password_confirmation"
              placeholder="تأكيد كلمة المرور"
              required
              value={formData.password_confirmation}
              onChange={(e) => setFormData(prev => ({ ...prev, password_confirmation: e.target.value }))}
            />
          </Form.Group>

          <Form.Group className="button py-3">
            <Button className="w-100"
              type="submit"
              disabled={!formData.password || !formData.password_confirmation}
            >تعيين كلمة المرور الجديدة</Button>
          </Form.Group>
        </Form>
      </section>
    </div>
  );
};

export default ResetPassword;
