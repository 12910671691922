import { Link, useParams, useNavigate, Navigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { Accordion } from "react-bootstrap";

import LoadingSpinner from "../shared/LoadingSpinner";
import {
  fetchCourseCertificate,
  fetchCourseDetails,
  fetchCurrentUser,
  openCourse as openCourseApi,
} from "../../api";
import "./course-details.scss";
import { openModel } from "./../shared/Model";
import { useState } from "react";

const CourseDetails = () => {
  const [videoPath, setVideoPath] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, isError: coursesError } = useQuery(["course", id], () =>
    fetchCourseDetails(id)
  );
  const { refetch } = useQuery(["download-course-certificate"], () =>
    fetchCourseCertificate(data?.data?.data?.certificate),
    { enabled: false }
  );

  const { mutate: openCourse, isLoading: openCourseLoading } = useMutation(
    openCourseApi,
    {
      onSuccess: res => {
        if (res.data.message === "You opened this course before") {
          openModel({
            title: "انت مشترك بالفعل",
            content: "الذهاب الى الكورس الان",
            onOk: () => {
              navigate(`/course-lessons/${id}`);
            },
          });
        } else {
          openModel({
            title: "تم الاشتراك",
            content: "تم الاشتراك فى الكورس بنجاح ، الذهاب الى الكورس الان",
            onOk: () => {
              navigate(`/course-lessons/${id}`);
            },
          });
        }
      },
    }
  );

  const { data: userData, isError: userError } = useQuery("current-user", fetchCurrentUser);

  const course = data?.data?.data;
  const modules = data?.data?.modules;
  const user = userData?.data?.data;

  const startCourseHandler = () => {
    if (!user) {
      return openModel({
        title: "سجل اولا",
        content: "غير مصرح لك ، برجاء تسجيل الدخول",
        onOk: () => {
          navigate(`/login`);
        },
      });
    }

    if (Number(course.is_free)) {
      return openCourse(id);
    }

    if (!user.is_subscribed) {
      return openModel({
        title: "غير مشترك",
        content: "برجاء الاشتراك فى احدى الباقات اولا",
        onOk: () => {
          navigate(`/pricing`);
        },
      });
    }

    if (user.is_subscribed) {
      openCourse(id);
    }
  };


  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (coursesError || userError) ? <Navigate to="/login" replace={true} /> : (
    <div className="course-details">
      <div>
        <div className="course-header position-relative py-5 px-3">
          <div className="container">
            <h2 className="text-white">{course.title}</h2>
            <p className="text-white">{course.description}</p>
            <p className="my-3 text-white d-flex align-items-center">
              <span className="text-white">بواسطة: </span>
              <Link
                to={`/lecturer/${course.lecturer?.id}`}
                className="instructor mx-2 text-success"
              >
                {course.lecturer?.name}
              </Link>
            </p>
          </div>
        </div>

        <div className="preview position-absolute vh-100">
          <div className="card h-75">
            {course.course_cover_path ? (
              <img
                src={`${process.env.REACT_APP_BASE_URL}/${course.course_cover_path}`}
                className="card-img-top"
                alt={course.title}
              />
            ) : (
              // TODO: replace with elegant loading image
              <div className="spinner-grow" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
            <div className="card-body">
              <h4>محتوى الكورس: </h4>
              <p className="card-text">{course.description}</p>
              <div className="d-flex justify-content-between">

                <button
                  className="btn btn-primary"
                  onClick={startCourseHandler}
                  disabled={openCourseLoading}
                >
                  ابدأ التعلم الآن
                </button>
                {!!data?.data?.data?.certificate &&
                  <button
                    className="btn btn-success"
                    onClick={() => refetch()}
                  >
                    تحميل الشهادة
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="skills py-5 mt-5 w-50">
            <h2>ما سوف تتعلمه في هذا الكورس: </h2>
            <ul className="mt-5 row">
              {course.skills.map((skill, index) => (
                <li className="col-md-6" key={index}>
                  <i className="fa-solid fa-check text-success"></i>
                  <span>{skill}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="instructor-details py-5">
          <div className="container">
            <div className="w-50">
              <h2 className="mb-5">تعريف بالمحاضر</h2>
              <div className="d-flex align-items-center justify-content-center">
                <div className="instructor-social d-flex flex-column justify-content-center align-items-center">
                  {course.lecturer?.photo ? (
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/${course.lecturer?.photo}`}
                      alt={course.lecturer?.name}
                      className="instructor-img"
                    />
                  ) : (
                    // TODO: replace with elegant loading image
                    <div className="spinner-grow" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  <div className="social d-flex my-3">
                    <a href={course.lecturer?.linkedin_link} className="mx-1">
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a href={course.lecturer?.twitter_link} className="mx-1">
                      <i className="fa-brands fa-twitter-square"></i>
                    </a>
                    <a href={course.lecturer?.github_link} className="mx-1">
                      <i className="fa-brands fa-github"></i>
                    </a>
                  </div>
                </div>
                <div>
                  <h5 className="text-success mb-4">
                    مرحبا! أنا {course.lecturer?.name}
                  </h5>
                  <p className="mb-4">{course.lecturer?.bio}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="course-lecs w-50 py-5">
            <Accordion defaultActiveKey={0}>
              {modules.map((module, index) => (
                <Accordion.Item eventKey={index} key={module.id}>
                  <Accordion.Header>
                    #{index + 1} {module.title}
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="flex-column">
                      {module.videos.map(video => {
                        return (
                          <li
                            className="details-lesson d-flex justify-content-between align-items-center py-1 px-2"
                            key={video.id}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              <p className="mb-0">
                                <i className="fa-solid fa-circle-play"></i>
                              </p>
                              <h6 className="lesson-title mx-2 mb-0">
                                {video.title}
                              </h6>
                            </div>
                            {Number(video.is_free) ? (
                              <button
                                className="btn btn-success py-1"
                                onClick={() => setVideoPath(video.path)}
                              >
                                تشغيل
                              </button>
                            ) : null}
                          </li>
                        );
                      })}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            {videoPath ? (
              <button
                className="modal d-flex justify-content-center align-items-center"
                style={{ cursor: "default" }}
                onClick={() => setVideoPath(null)}
              >
                <video width="720" height="420" controls autoPlay>
                  {/* TODO: replace with real one */}
                  <track default kind="captions" src=""></track>
                  <source
                    src={`${process.env.REACT_APP_BASE_URL}/${videoPath}`}
                    type={`video/${course.intro_path.split(".")[1]}`}
                  ></source>
                </video>
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDetails;
