import axios from "../axois-client";

export const fetchCourseDetails = id => {
  return axios.get(`/courses/${id}`);
};
export const fetchCourseCertificate = id => {
  return axios.get(`/certificate/${id}`);
};

export const fetchCourseList = name => {
  return axios.get(`/courses?lecturer_name=${name}`);
};

export const fetchCourseByTitle = name => {
  return axios.get(`/my-courses?search_key=${name}`);
};
