import MyLearning from "../components/myLearning/MyLearning";

const MyLearningPage = () => {
  return (
    <div className="My-learning-page">
      <MyLearning />
    </div>
  );
};

export default MyLearningPage;
