import { useState } from "react";
import { Link } from "react-router-dom";
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./course.scss";

const Course = ({ course }) => {
  const [modal, setModal] = useState(false);

  const handleCourseHours = (courseHours = '00:00:00') => {
    let word, time;
    const hours = courseHours.split(":")[0];
    const minutes = courseHours.split(":")[1];
    const seconds = courseHours.split(":")[2];
    if (+hours) {
      word = 'ساعة';
      time = `${+hours}${+minutes ? `:${minutes}` : ''}`;
    }
    else if (+minutes) {
      word = 'دقيقة';
      time = `${+minutes}${+seconds ? `:${seconds}` : ''}`;
    }
    else {
      word = 'ثانية';
      time = +seconds;
    }
    return `${time} ${word}`;
  }
  return (
    <div className="course">
      <div
        className={`card course__card p-2 my-2 ${Number(course.is_free) ? "free__course" : ""
          }`}
      >
        <Link to={`/courses/${course.id}`}>
          <img
            src={`${process.env.REACT_APP_BASE_URL}/${course.course_cover_path}`}
            className="card-img-top course-img"
            alt={course.title}
          />
        </Link>
        <div className="card-body">
          <Link to={`/courses/${course.id}`}>
            <h5 className="card-title course__title">{course.title}</h5>
          </Link>
          <div className="d-flex flex-column">
            <small>
              <i className="fa-regular fa-clock px-1 text-primary"></i>
              {handleCourseHours(course?.course_hours)}
            </small>
            <small>
              <i className="fa-solid fa-video px-1 text-primary"></i>
              {course?.videos_count} درس
            </small>
          </div>
          <p className="card-text my-3">{course.description}</p>
          <div className="instructor d-flex align-items-center my-4">
            <div className="img"></div>
            <p>
              <Link
                to={`/lecturer/${course.lecturer?.id}`}
                className="text-dark"
              >
                {course.lecturer.name}
              </Link>
            </p>
          </div>
          <div className="buttons d-flex justify-content-between">
            <button className="preview-course" onClick={() => setModal(true)}>
              مقدمة
            </button>
            <Link to={`/courses/${course.id}`} className="details-link">
              تفاصيل الكورس
            </Link>
            <Modal
              show={modal}
              onHide={() => setModal(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              contentClassName="p-0 border-none bg-transparent"
            >
              <Modal.Body className="p-0" style={{ height: "430px" }}>
                <video width="100%" height="430" controls autoPlay>
                  <track default kind="captions" src=""></track>
                  <source
                    src={`${process.env.REACT_APP_BASE_URL}/${course.intro_path}`}
                    type={`video/${course.intro_path.split(".")[1]}`}
                  ></source>
                </video>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
