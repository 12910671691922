import { useEffect } from "react";
import ReactPlayer from "react-player";
import { useQuery, useMutation } from "react-query";

import { fetchVideo, openVideo as openVideoApi } from "./../../../../api";
import LoadingSpinner from "./../../../shared/LoadingSpinner";
import { queryClient } from "./../../../../api/query-client";
import "./Content.scss";

const Content = ({ videoId, course, lastViewedVideo, playLastVideo }) => {
  const { data, isLoading } = useQuery(["video", playLastVideo ? lastViewedVideo ?? videoId : videoId], () =>
    fetchVideo(playLastVideo ? lastViewedVideo ?? videoId : videoId)
  );
  const { mutate: openVideo } = useMutation(openVideoApi, {
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["my-courses"] });
    },
  });

  const video = data?.data?.data;

  useEffect(() => {
    openVideo(videoId);
  }, [videoId]);

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <section className="course__content">
      <div className="course__video mb-2">
        <ReactPlayer
          url={`${process.env.REACT_APP_BASE_URL}/${video.path}`}
          controls={true}
          playing={false}
          width={"100%"}
          height={400}
        />
      </div>
      <div className="course__details">
        <header className="my-4">
          <h4>{video.title}</h4>
        </header>
        <p>{video.description}</p>
      </div>
    </section>
  );
};

export default Content;
