import { useQuery } from "react-query";

import { fetchFaqData } from "../../api";
import LoadingSpinner from "../shared/LoadingSpinner";

import "./Questions.scss";
const Questions = () => {
  const { data, isLoading } = useQuery("faq", fetchFaqData);

  const faqData = data?.data?.data;

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <section className="Questions">
      <div className="container">
        <h2 className="Questions_header text-center"> الاسئلة الشائعة </h2>
        <div className="accordion" id="accordionExample">
          {faqData.map((faqQuestion, index) => (
            <div className="accordion-item" key={faqQuestion.id}>
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#remove_later_${faqQuestion.id}`}
                  aria-expanded={index === 0}
                  aria-controls={`remove_later_${faqQuestion.id}`}
                >
                  {faqQuestion.question}
                </button>
              </h2>
              <div
                id={`remove_later_${faqQuestion.id}`}
                className={`accordion-collapse collapse ${index === 0 && "show"}`}
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">{faqQuestion.answer}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Questions;
