import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { store } from "./redux";
import { Provider } from "react-redux";
import { QueryClientProvider } from "react-query";
import App from "./App";
import { queryClient } from "./api";
// bootstrap js bundle
import "bootstrap/dist/js/bootstrap.bundle.min";
// style
import "bootstrap/dist/css/bootstrap.rtl.min.css";
// import "~slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";
import "./index.scss";

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <React.StrictMode>
          <ConfigProvider direction="rtl">
            <App />
          </ConfigProvider>
        </React.StrictMode>
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
);
