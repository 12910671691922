import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { answerQuestion } from '../../../../api';
import './Questions.scss';

const ModuleQuestions = ({ moduleTitle, moduleQuestions }) => {
    const [answerId, setAnswerId] = useState(null);
    const [questionNumber, setQuestionNumber] = useState(0);
    const [questionAnswer, setQuestionAnswer] = useState({ id: '' });
    const [moduleQuestionsCopy, setModuleQuestionsCopy] = useState(moduleQuestions);
    const { isLoading } = useQuery(["answer-question", answerId], async () => {
        if (!answerId) return;
        await answerQuestion(answerId)
        setAnswerId(null)
    }
    );

    const checkAnswerAndClass = (isTrue, myAnswer, isClass = false) => {
        if (+isTrue && (+myAnswer || myAnswer === null)) {
            return isClass ? 'checked__correctAnswer' : <span className='answer__status text-success'>✅</span>;
        }
        if (!+isTrue && myAnswer !== null) {
            return isClass ? 'checked__incorrectAnswer' : <span className='answer__status text-danger'>❌</span>;
        }
    }

    const handleSubmit = (e, questionIndex) => {
        e.preventDefault();

        const updatedModuleQuestions = [...moduleQuestionsCopy];
        const updatedQuestion = updatedModuleQuestions[questionIndex]
        updatedQuestion.is_answered = true;
        const updatedAnswer = updatedQuestion.answers.find(answer => answer.id === questionAnswer.id)
        updatedAnswer.my_answer = +updatedAnswer.is_true ? '1' : '0';

        setModuleQuestionsCopy(updatedModuleQuestions)
        setAnswerId(questionAnswer.id)
    }

    useEffect(() => {
        setAnswerId(null);
        setQuestionNumber(0);
        setQuestionAnswer({ id: '' })
        setModuleQuestionsCopy(moduleQuestions)
    }, [moduleTitle])


    return (
        <section className='module__questions'>
            <h2 className='text-capitalize text-end'>{moduleTitle} questions</h2>
            <Row className='wrapper mx-0'>
                <Col xs={12}>
                    {moduleQuestions.map(({ id: QuestionId, title, answers }, index) => {
                        return questionNumber === index &&
                            <div key={QuestionId} className='question text-center'>
                                <h4 className='d-flex flex-row-reverse justify-content-center'>
                                    <span className='ps-1'>{`(${index + 1}`}</span>
                                    <span>{title}</span>
                                </h4>
                                <Form className='module__questionsForm text-center' onSubmit={(e) => handleSubmit(e, index)}>
                                    {answers.map(({ id: answerId, content, is_true: isTrue, my_answer: myAnswer }) => (
                                        <InputGroup key={answerId} className='justify-content-center align-items-center'>
                                            <div className='answer__mark'>
                                                {moduleQuestionsCopy[index].is_answered && checkAnswerAndClass(isTrue, myAnswer)}
                                            </div>
                                            <InputGroup.Radio
                                                id={`answer_${answerId}`}
                                                name={questionAnswer.id}
                                                value={questionAnswer.id}
                                                onChange={() => setQuestionAnswer({ id: answerId })}
                                                disabled={moduleQuestionsCopy[index].is_answered}
                                            />
                                            <label
                                                htmlFor={`answer_${answerId}`}
                                                className={moduleQuestionsCopy[index].is_answered ? checkAnswerAndClass(isTrue, myAnswer, true) : ''}
                                            >
                                                {content}
                                            </label>
                                        </InputGroup>
                                    ))}
                                    {!moduleQuestionsCopy[index].is_answered && <Button key={QuestionId} type='submit' className='form__button' disabled={!questionAnswer.id || isLoading}>تاكيد اجابتك</Button>}
                                    {moduleQuestionsCopy[index].is_answered && index < moduleQuestions.length - 1 && <Button key={QuestionId} type='button' className='form__button' onClick={() => {
                                        setQuestionNumber(prev => prev + 1);
                                        setQuestionAnswer({ id: '' });
                                    }}>التالي</Button>}
                                </Form>
                            </div>
                    })}
                </Col>
                <Col>
                </Col>
            </Row>
        </section>
    );
};

export default ModuleQuestions;
