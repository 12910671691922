export const formatToSecondes = seconds => {
  // format 3 seconds to '1970-01-01T00:00:03.000Z'
  const formatted = new Date(Number(seconds) * 1000).toISOString();


  // If no hours part just return hh:mm:ss
  if (
    formatted.substring(11, 12) === "0" &&
    formatted.substring(12, 13) === "0"
  )
    return formatted.substring(14, 19);

  // else return mm:ss
  return formatted.substring(11, 19);
};
