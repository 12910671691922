import { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import { useQuery } from "react-query";
import { IoIosSearch } from "react-icons/io";
import { Form, Input } from "antd";

import Course from "./Course";
import LoadingSpinner from "../shared/LoadingSpinner";
import { fetchCourseList } from "../../api";

// style
import "./courses.scss";

const Courses = () => {
  const [lecturerName, setLecturerName] = useState("");
  const [debouncedLecturerName] = useDebounce(lecturerName, 600);
  const { data, isLoading, refetch } = useQuery(["course-details"], () =>
    fetchCourseList(lecturerName)
  );

  const coursesData = data?.data?.data;

  useEffect(() => {
    refetch();
  }, [debouncedLecturerName]);

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <section className="courses">
      <div className="container">
        <div className="row">
          <Form className="py-5 d-flex flex-column justify-content-center align-items-center">
            <header className="courses__header">
              <h2>ابحث في جميع الكورسات</h2>
            </header>
            <div className="search-input__wrapper">
              <Input
                name=""
                placeholder="باسم المدرب"
                className="shadow-none w-50"
                onChange={e => setLecturerName(e.target.value)}
              />
              <IoIosSearch className="search-icon" size={16} />
            </div>
          </Form>
          {coursesData.map(course => (
            <div key={course.id} className=" col-md-6 col-xl-4">
              <Course course={course} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Courses;
