import { Link } from "react-router-dom";
import LogoImage from "../../assets/dark-logo.png";
import instagram from "../../assets/instagram.png";
import facebook from "../../assets/facebook.png";
import linkedin from "../../assets/linkedin.png";
import youtube from "../../assets/youtube.jpg";
import "./footer.scss";

const Footer = () => {
  return (
    <>
      <section className="sub-footer with-border-top">
        <div className="container">
          <header className="sub-footer__header">
            <figure>
              <img src={LogoImage} alt="codinghub-logo" />
            </figure>
            <h2>تعلم البرمجة باللغة العربية</h2>
            <p>معلومات عن المنصة فى هذا القسم تكتب هنا...</p>
          </header>
          <ul className="list-unstyled social__mediaLinks mb-5">
            <li className="instagram">
              <a href="https://www.instagram.com/codinghutedu/" target="_blank" rel="noreferrer">
                <img src={instagram} alt="instagram" />
              </a>
            </li>
            <li className="facebook">
              <a href="https://www.facebook.com/profile.php?id=100092238259976" target="_blank" rel="noreferrer">
                <img src={facebook} alt="facebook" />
              </a>
            </li>
            <li className="linkedin">
              <a href="https://www.linkedin.com/company/codinghut" target="_blank" rel="noreferrer">
                <img src={linkedin} alt="linkedin" />
              </a>
            </li>
            <li className="youtube">
              <a href="https://www.youtube.com/channel/UCzoQCDbbiI_fzvf9q7mC-BA" target="_blank" rel="noreferrer">
                <img src={youtube} alt="youtube" />
              </a>
            </li>
          </ul>
          <ul className="sub-footer__links">
            <li className="item">
              <Link to="/terms">سياسة الخصوصية</Link>
            </li>
            <li className="item">
              <Link to="/faq">الأسئلة الشائعة</Link>
            </li>
            <li className="item">
              <Link to="/pricing">الباقات</Link>
            </li>
          </ul>
        </div>
      </section>
      <footer className="footer">
        <div className="container">
          <p className="footer__rights my-1">جميع الحقوق محفوظة لدي ODING HUT&gt; </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
