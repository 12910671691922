import { notification } from "antd";

export const openNotification = ({ message, description, isNotError }) => {
  const args = {
    message,
    description,
    duration: 3,
    placement: "bottom",
  };

  isNotError ? notification.open(args) : notification.error(args);
};
