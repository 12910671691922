import axios from "axios";
import {
  genericErrorHandler,
  getLocalStoredData,
  getSessionStorageData,
  AUTH_TOKEN,
} from "../helper";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

axios.interceptors.request.use(request => {
  if (request.headers["Content-Type"] !== "application/x-www-form-urlencoded") {
    request.headers["Content-Type"] = "application/json";
  }

  const accessToken =
    getLocalStoredData(AUTH_TOKEN) || getSessionStorageData(AUTH_TOKEN);

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }
  request.headers["Access-Control-Allow-Origin"] = "*";

  return request;
});

// Add a response interceptor
axios.interceptors.response.use(
  response => response,
  function (error) {
    error.handleGlobally = genericErrorHandler(error);

    return Promise.reject(error);
  }
);

export default axios;
