import { Modal } from "antd";

export const openModel = ({ title, content, onOk, isNotError: isError }) => {
  const args = {
    content,
    title,
    okText: "حسنا",
    closable: true,
    maskClosable: true,
    centered: true,
    onOk,
  };

  const model = isError ? Modal.error(args) : Modal.info(args);
  return model;
};
