import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import myCoursesSlice from "./slices/myCoursesSlice";
import userInfoSlice from './slices/userInfoSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    myCourses: myCoursesSlice,
    userInfo: userInfoSlice
  },
});
