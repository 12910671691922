import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Container, Nav, Navbar, Dropdown } from "react-bootstrap";
import { Button, Divider, Progress } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { fetchCurrentUser, fetchMyCourses } from "../../api";
import { setIsLogedIn, setMyCourses, setToken, setUser, setUserInfo } from "../../redux";
// import { AUTH_TOKEN, calculatePercentage, getLocalStoredData, getSessionStorageData } from "./../../helper";
import { calculatePercentage } from "./../../helper";
import Logo from "../../assets/dark-logo.png";
import "./header.scss";
import { useEffect } from "react";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLogedIn = useSelector(state => {
    return state.auth.isLogedIn;
  });

  // const sessionStorageData = getSessionStorageData(AUTH_TOKEN);
  // const localStoredData = getLocalStoredData(AUTH_TOKEN);

  const { data } = useQuery("my-courses", fetchMyCourses, {
    onSuccess: (data) => dispatch(setMyCourses(data.data.data)),
    enabled: isLogedIn
  });
  const myCourses = data?.data?.data || [];

  const { data: userData, isLoading, refetch } = useQuery("current-user", fetchCurrentUser, {
    onSuccess: (data) => dispatch(setUserInfo(data.data.data)),
  });
  const user = userData?.data?.data;
  const showUserName = !isLoading && isLogedIn && user;

  const logout = () => {
    dispatch(setUser(null));
    dispatch(setToken(null));
    dispatch(setIsLogedIn(false));
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    refetch();
  }, [isLogedIn])



  return (
    <header className="header">
      <Container fluid className="header__container d-flex py-2 align-items-center">
        <Navbar bg="white" expand="lg" className="flex-row-reverse">
          <Navbar.Brand className="me-0">
            <div className="logo-container">
              <NavLink to="/">
                <figure className="mb-0 logo">
                  <img src={Logo} alt="Logo" className=" w-100" />
                </figure>
              </NavLink>
            </div>
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="navbarScroll"
            className="shadow-none border-0"
          />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0 align-items-center"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <div className={`user__name ${showUserName ? 'loged_in' : ''} nav-link d-flex align-items-center`}>
                {showUserName &&
                  <>
                    <span className="pe-1 mb-1">
                      <UserOutlined className="fs-5" />
                    </span>
                    <span>مرحبا</span>
                    <NavLink className="nav-link" to="/student-settings">
                      {`${user?.first_name} ${user?.last_name}`}
                    </NavLink>
                  </>
                }
              </div>
              <NavLink className="nav-link" to="/">
                الرئيسية
              </NavLink>
              <NavLink className="nav-link" to="/courses">
                جميع الكورســات
              </NavLink>
              <NavLink className="nav-link" to="/pricing">
                خطط الأسعار
              </NavLink>

              {!isLogedIn ? (
                <>
                  <NavLink className="nav-link" to="/login">
                    تسجيل الدخول
                  </NavLink>
                  <NavLink className="nav-link" to="register">
                    إنشاء حساب
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink className="nav-link" to="/student-settings">
                    الاعدادات
                  </NavLink>
                  <Dropdown>
                    <Dropdown.Toggle
                      className="nav-link"
                      variant="default"
                      id="dropdown-basic"
                    >
                      كورساتي
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="my-learning__dropdown ">
                      {myCourses.map((course, index) => {
                        if (index > 1) return null;
                        return (
                          <div key={course.id}>
                            <Dropdown.Item>
                              <Link to={`/course-lessons/${course.id}`}>
                                <div className="d-flex">
                                  <figure className="my-learning__fig">
                                    <img
                                      src={`${process.env.REACT_APP_BASE_URL}/${course.course_cover_path}`}
                                      alt={course.title}
                                    />
                                  </figure>
                                  <div className="my-learning__desc">
                                    <h6>{course.title}</h6>
                                    <Progress
                                      percent={calculatePercentage(course)}
                                      size="small"
                                      status="normal"
                                    />
                                  </div>
                                </div>
                              </Link>
                            </Dropdown.Item>

                            <Divider
                              style={{
                                marginTop: ".25rem",
                                marginBottom: "0.25rem",
                              }}
                            />
                          </div>
                        );
                      })}

                      <Dropdown.Item>
                        <Button
                          type="default"
                          className="w-100 my-learning__btn"
                        >
                          <Link to="/my-learning">الذهاب إلي كورساتي</Link>
                        </Button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <button
                    className="bg-transparent border-0 nav-link"
                    onClick={logout}
                  >
                    تسجيل الخروج
                  </button>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  );
};

export default Header;
