import { Row, Col } from "antd";
import CourseSidebar from "./components/Sidebar/Sidebar";
import CourseContent from "./components/Content/Content";
import ModuleQuestions from "./components/Content/Questions";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import "./CoursesLesson.scss";
import { fetchCourseDetails } from "./../../api";
import LoadingSpinner from "./../shared/LoadingSpinner";
import { useState } from "react";

const Courses = () => {
  const [videoId, setVideoId] = useState();
  const [playLastVideo, setPlayLastVideo] = useState(true);
  const [moduleTitle, setModuleTitle] = useState(null);
  const [moduleQuestions, setModuleQuestions] = useState([]);
  const { id } = useParams();
  const { data, isLoading } = useQuery(["my-course", id], () =>
    fetchCourseDetails(id)
  );
  const lastViewedVideo = data?.data?.data?.last_viewed_video;
  const course = data?.data?.data;
  const modules = data?.data?.modules;

  const changeVideoHandler = (id, moduleTitle, moduleQuestions = null) => {
    setVideoId(id);
    playLastVideo && setPlayLastVideo(false);
    if (!moduleQuestions) return;
    setModuleQuestions(moduleQuestions);
    setModuleTitle(moduleTitle)
  };

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <main className="course">
      <div className="course__header">
        <h4 className="course__h4">{course.title} </h4>
      </div>
      <Row className="flex-1">
        <Col
          className="course__header-col"
          xs={{ span: 24, order: 2 }}
          md={{ span: 6, order: 1 }}
        >
          <CourseSidebar modules={modules} onChangeVideo={changeVideoHandler} />
        </Col>
        <Col
          className="course__content-col"
          xs={{ span: 24, order: 1 }}
          md={{ span: 18, order: 1 }}
        >
          {!!modules.length && typeof (videoId) !== 'string' && (
            <CourseContent videoId={videoId || modules[0].videos[0]?.id} course={course} lastViewedVideo={lastViewedVideo} playLastVideo={playLastVideo} />
          )}
          {!!modules.length && typeof (videoId) === 'string' && <ModuleQuestions moduleTitle={moduleTitle} moduleQuestions={moduleQuestions} />}
        </Col>
      </Row>
    </main>
  );
};

export default Courses;
